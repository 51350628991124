import http from '@/config/request'

export default {
	index:(data) =>{
		return http({
			url: 'api/supplier/index/index',
			method: 'post',
			params: data
		})
	},

	login:(data) =>{
		return http({
			url: 'api/supplier/user/login',
			method: 'POST',
			params:  data
		})
	},


	orderList:(data) =>{
		return http({
			url: 'api/supplier/order/index',
			method: 'POST',
			params:  data
		})
	},
	orderDetail:(data) =>{
		return http({
			url: 'api/supplier/order/detail',
			method: 'POST',
			params:  data
		})
	},

	express:(data) =>{
		return http({
			url: 'api/supplier/order/sendOrder',
			method: 'POST',
			params:  data
		})
	},


}