import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import api from '@/config/api'

router.beforeEach((to,from,next) => {
	next()
})

const app = createApp(App);
console.log(app)
app.config.globalProperties.$api = api;
app.provide('$api', api)
app.use(ElementPlus)
app.use(router).mount('#app')

