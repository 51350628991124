<template>
	<div class="head aliL pr40 flex flexC">
		<img class="logo" src="../../assets/img/logo.png" />
		<span class="ml40 flex1">纷享未来药房供应链管理系统</span>
		<el-button size="mini" plain>{{username}}注销</el-button>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				usernanme:''
			}
		},
		mounted() {
		  var username = sessionStorage.getItem("username");
		  this.username = username;
		}
	}
</script>

<style lang="scss" scoped>
.head {background-color: #545c64;color: #fff;margin: 0 !important;height: 7vh; padding-left: 30px;
	.logo {width: 40px;height: 40px;vertical-align: middle;}
}
</style>
