<template>
	<el-container>
		<el-header>
			<admin-head></admin-head>
		</el-header>
		<el-container>
			<el-aside width="200px">
				<admin-aside></admin-aside>
			</el-aside>
			<el-main class="p20 h93">
				<router-view class="bgff shadow r6 p20"/>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import adminHead from './head.vue';
	import adminAside from './aside.vue'
	export default {
		components: {adminHead,adminAside}
	}
</script>

<style lang="scss" scoped>
	.el-header {
		padding: 0;
		height: auto;
	}
  .h93 {height: 93vh;overflow-y: auto}
</style>
