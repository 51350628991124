import axios from 'axios'

import { baseUrl } from './env'
import router from "../router";

// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

const http = axios.create({
	baseURL:baseUrl, // url = base url + request url
	withCredentials: false, // send cookies when cross-domain requests
	timeout: 500000 // request timeout
})
http.interceptors.request.use(
    config => {
		let token=sessionStorage.getItem("token")||'';
		console.log(token)
		
			config.headers={
				'token':token,
			}
		
		return config
	},
	error => {
		return Promise.reject(error)
	}
)
// response interceptor
http.interceptors.response.use(
	response => {
		const res = response.data
		console.log("======john===="+JSON.stringify(res))

		if (res.code&&res.code == 401) {
			if(res.code== 401){ //需要登录
				sessionStorage.setItem("token",'');
				this.$route.push('/login');
			}

			return Promise.reject(res.msg || 'error')
		} else {
			return res
		}
	},
	error => {
		if(error.message == 'Request failed with status code 401'){
			console.info("====401===");
			sessionStorage.setItem("token",'');
			router.push('/login');
		}


		return Promise.reject(error)
	}
)

export default http

