<template>
	<router-view/>
</template>

<style lang="scss">
	@import "./assets/css/common.scss";

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #333;
		font-size: 14px;

		
	}
</style>
