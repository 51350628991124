<template>
 <el-menu
    active-text-color="#ffd04b"
    background-color="#545c64"
    class="el-menu-vertical-demo"
    :default-active="$route.path"
    text-color="#fff"
	router>
  <div v-for="(item,index) in list">
	  <el-sub-menu v-if="item.children" :index="item.path">
	    <template #title>
	      <el-icon>
			  <menu v-if="item.name == 'home'"/>
			  <list v-if="item.name == 'orders'"/>
			  <shop v-if="item.name == 'shop'"/>
			  <goods-filled v-if="item.name == 'goods'"/>
			  <ticket v-if="item.name == 'promotion'"/>
		  </el-icon>
	      <span>{{item.title}}</span>
	    </template>
		<el-menu-item v-for="(tmp,idx) in item.children" :index="tmp.path" v-show="tmp.menu">{{tmp.title}}</el-menu-item>
	  </el-sub-menu>
	  <el-menu-item v-else :index="item.path">
	    <el-icon><icon-menu /></el-icon>
	    <span>{{item.title}}</span>
	  </el-menu-item>
  </div>
  
  </el-menu>
</template>

<script>
import {Menu as IconMenu, List, Shop, Ticket, GoodsFilled,} from '@element-plus/icons'

export default {
	data() { 
		return { };
	},
  components: {IconMenu, List, Shop, Ticket, GoodsFilled },
  computed:{
	  list() {
		  let arr = this.$router.options.routes;
		  arr = arr && arr.filter(item => { return item.menu == true})
		  let home = { path: "/home", name: "home", title: '首页', menu: true}
		  return [home,...arr];
	  }
  }
}
</script>
<style lang="scss" scoped>
	.el-menu {height: 93vh;}
</style>
