import { createRouter,createWebHistory} from "vue-router";
import Layout from '../components/layout/index.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
		{
		    path: "/login",
		    name: "login",
			title: '登录',
			menu: false,
		    component: () => import("../views/signUp/login.vue")
		},
		{ path: "/", redirect: "/home",menu: false},
		{
		    path: "/",
		    name: "home",
			title: '首页',
			menu: false,
			component: Layout,
			children: [
				{path: '/home',title: '首页',menu: false,component: () => import("../views/home/index.vue")}
			]
		},
		{
		    path: "/orders",
		    name: "orders",
			title: '订单管理',
			menu: true,
			component: Layout,
			children: [
				{path: '/orders/index',title: '订单列表',menu: true,component: () => import("../views/orders/index.vue")}
			]
		},
	]
});
export default router


